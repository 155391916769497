exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-app-js": () => import("./../../../src/pages/app.js" /* webpackChunkName: "component---src-pages-app-js" */),
  "component---src-pages-components-js": () => import("./../../../src/pages/components.js" /* webpackChunkName: "component---src-pages-components-js" */),
  "component---src-templates-forgotpassword-js": () => import("./../../../src/templates/forgotpassword.js" /* webpackChunkName: "component---src-templates-forgotpassword-js" */),
  "component---src-templates-home-js": () => import("./../../../src/templates/home.js" /* webpackChunkName: "component---src-templates-home-js" */),
  "component---src-templates-login-js": () => import("./../../../src/templates/login.js" /* webpackChunkName: "component---src-templates-login-js" */),
  "component---src-templates-questionnaire-js": () => import("./../../../src/templates/questionnaire.js" /* webpackChunkName: "component---src-templates-questionnaire-js" */),
  "component---src-templates-resetpassword-js": () => import("./../../../src/templates/resetpassword.js" /* webpackChunkName: "component---src-templates-resetpassword-js" */),
  "component---src-templates-resource-detail-js": () => import("./../../../src/templates/resource-detail.js" /* webpackChunkName: "component---src-templates-resource-detail-js" */),
  "component---src-templates-resourcelisting-js": () => import("./../../../src/templates/resourcelisting.js" /* webpackChunkName: "component---src-templates-resourcelisting-js" */),
  "component---src-templates-results-js": () => import("./../../../src/templates/results.js" /* webpackChunkName: "component---src-templates-results-js" */),
  "component---src-templates-signup-js": () => import("./../../../src/templates/signup.js" /* webpackChunkName: "component---src-templates-signup-js" */)
}

